import { FunctionComponent } from "react";

export type FrameComponent8Type = {
  className?: string;
};

const FrameComponent8: FunctionComponent<FrameComponent8Type> = ({
  className = "",
}) => {
  return (
    <div
      className={`w-full h-[1234.7px] absolute !m-[0] top-[0px] right-[0px] left-[0px] ${className}`}
    >
      <img
        className="absolute top-[0px] left-[0px] w-full h-full object-cover mix-blend-overlay z-[1]"
        alt=""
        src="/input@2x.png"
      />
      <img
        className="absolute top-[480px] left-[1587px] w-[180px] h-0 z-[2]"
        alt=""
        src="/group-219.svg"
      />
      <img
        className="absolute top-[112px] left-[723px] w-[38px] h-[38px] object-contain z-[2]"
        loading="lazy"
        alt=""
        src="/quotationmark-1@2x.png"
      />
      <img
        className="absolute top-[351px] left-[801px] w-[967px] h-px z-[2]"
        alt=""
        src="/vector-46.svg"
      />
      <img
        className="absolute top-[401px] left-[801px] rounded-[50%] w-[159px] h-[159px] object-contain z-[2]"
        loading="lazy"
        alt=""
        src="/ellipse-16@2x.png"
      />
      <img
        className="absolute top-[425px] left-[152px] rounded-93xl w-[111px] h-28 object-contain z-[2]"
        loading="lazy"
        alt=""
        src="/download-1@2x.png"
      />
      <div className="absolute top-[1109px] left-[487px] rounded-10xs border-white border-[1px] border-solid box-border w-[22px] h-[22px] z-[2]">
        <div className="absolute top-[0px] left-[0px] rounded-10xs border-white border-[1px] border-solid box-border w-[19px] h-[19px] hidden" />
        <div className="absolute top-[3px] left-[3px] rounded-10xs bg-gainsboro-100 w-[19px] h-[19px] z-[1]" />
      </div>
      <img
        className="absolute top-[837px] left-[960px] w-[329.5px] h-0.5 z-[2]"
        alt=""
        src="/vector-47.svg"
      />
      <img
        className="absolute top-[945px] left-[960px] w-[711px] h-0.5 z-[2]"
        alt=""
        src="/vector-49.svg"
      />
      <img
        className="absolute top-[837px] left-[1342px] w-[329.5px] h-0.5 z-[2]"
        alt=""
        src="/vector-47.svg"
      />
      <img
        className="absolute top-[976px] left-[960px] w-[18px] h-4 object-contain z-[2]"
        alt=""
        src="/attachfile-1@2x.png"
      />
      <div className="absolute top-[1039px] left-[960px] border-white border-[1.5px] border-solid box-border w-5 h-5 z-[2]" />
    </div>
  );
};

export default FrameComponent8;
