import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type GroupComponent1Type = {
  className?: string;
  checkmark?: string;
  industryStandard?: string;
  weEnsureOurWorkMeets?: string;
  industryStandards?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propHeight?: CSSProperties["height"];
  propWidth?: CSSProperties["width"];
  propPadding1?: CSSProperties["padding"];
  propHeight1?: CSSProperties["height"];
};

const GroupComponent1: FunctionComponent<GroupComponent1Type> = ({
  className = "",
  propPadding,
  checkmark,
  propHeight,
  propWidth,
  propPadding1,
  industryStandard,
  propHeight1,
  weEnsureOurWorkMeets,
  industryStandards,
}) => {
  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const checkmarkIconStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
      width: propWidth,
    };
  }, [propHeight, propWidth]);

  const frameDiv1Style: CSSProperties = useMemo(() => {
    return {
      padding: propPadding1,
    };
  }, [propPadding1]);

  const industryStandardStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight1,
    };
  }, [propHeight1]);

  return (
    <div
      className={`self-stretch shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 flex flex-col items-start justify-start pt-[38px] pb-[37px] pl-[41px] pr-10 box-border gap-5 max-w-full text-center text-13xl text-whitesmoke font-inter ${className}`}
    >
      <div className="w-[353px] h-[291px] relative shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 hidden max-w-full" />
      <div
        className="self-stretch flex flex-row items-start justify-center"
        style={frameDivStyle}
      >
        <img
          className="h-[84px] w-[86px] relative object-cover z-[1]"
          alt=""
          src={checkmark}
          style={checkmarkIconStyle}
        />
      </div>
      <div
        className="self-stretch flex flex-col items-start justify-start gap-3"
        style={frameDiv1Style}
      >
        <h2
          className="m-0 h-[46px] relative text-inherit leading-[120px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]"
          style={industryStandardStyle}
        >
          {industryStandard}
        </h2>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-lg">
          <div className="relative leading-[150%] capitalize z-[1]">
            <p className="m-0">{weEnsureOurWorkMeets}</p>
            <p className="m-0">{industryStandards}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupComponent1;
