import { FunctionComponent } from "react";

export type FrameComponent4Type = {
  className?: string;
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = ({
  className = "",
}) => {
  return (
    <div
      className={`flex flex-row items-end justify-start gap-[79px] max-w-full text-left text-xl text-whitesmoke font-jacques-francois mq1350:flex-wrap ${className}`}
    >
      <div className="w-[442px] flex flex-col items-start justify-start gap-[135px] max-w-full text-13xl">
        <div className="w-[380px] flex flex-col items-start justify-start py-0 pl-0 pr-5 box-border gap-4 max-w-full">
          <h2 className="m-0 w-[303px] relative text-inherit leading-[76px] capitalize font-normal font-[inherit] flex items-center mq450:text-lgi mq450:leading-[46px] mq925:text-7xl mq925:leading-[61px]">
            Our team
          </h2>
          <div className="self-stretch relative text-lg leading-[120%] capitalize font-inter">
            <p className="m-0">Our dedicated team strives to deliver</p>
            <p className="m-0">excellence in every service we provide.</p>
          </div>
        </div>
        <div className="self-stretch h-[696px] flex flex-col items-start justify-start max-w-full text-xl">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
            loading="lazy"
            alt=""
            src="/rectangle-161@2x.png"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-1.5 box-border max-w-full">
            <div className="flex-1 flex flex-col items-start justify-start gap-[22px] max-w-full">
              <div className="self-stretch h-[26px] relative">
                <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-[430px] h-6 z-[1] mq450:text-base mq450:leading-[19px]">
                  01 Manas Mahendra
                </div>
                <img
                  className="absolute top-[25px] left-[52px] w-[186px] h-px"
                  alt=""
                  src="/vector-37.svg"
                />
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 text-base text-lightgray">
                <div className="relative leading-[120%] capitalize inline-block min-w-[113px]">{`Founder & Ceo`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[941px] w-[347px] flex flex-col items-start justify-end pt-0 px-0 pb-0.5 box-border max-w-full">
        <div className="self-stretch flex-1 flex flex-col items-start justify-start max-w-full">
          <img
            className="self-stretch h-[448px] relative max-w-full overflow-hidden shrink-0 object-cover"
            loading="lazy"
            alt=""
            src="/rectangle-162@2x.png"
          />
          <div className="self-stretch flex-1 flex flex-row items-start justify-start py-0 px-1.5 box-border max-w-full">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[5px] max-w-full">
              <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-5 gap-[5px]">
                <div className="self-stretch h-[37px] relative">
                  <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-full h-full z-[1] mq450:text-base mq450:leading-[19px]">
                    02 Suraj Kumar
                  </div>
                  <img
                    className="absolute top-[23px] left-[42px] w-[150px] h-px z-[2]"
                    alt=""
                    src="/vector-38.svg"
                  />
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-base text-lightgray">
                  <div className="relative leading-[120%] capitalize">
                    Chief UI/UX Designer
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 flex flex-row items-start justify-start py-0 px-[23px]">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-1">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                    loading="lazy"
                    alt=""
                    src="/rectangle-163@2x.png"
                  />
                  <div className="self-stretch flex flex-row items-start justify-start py-0 px-1.5">
                    <div className="h-[37px] flex-1 relative">
                      <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-full h-full mq450:text-base mq450:leading-[19px]">
                        03 Swami Kumar
                      </div>
                      <img
                        className="absolute top-[24px] left-[34px] w-[87px] h-px z-[1]"
                        alt=""
                        src="/vector-39.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-base text-lightgray">
                <div className="relative leading-[120%] capitalize inline-block min-w-[119px]">
                  Senior Designer
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1095px] w-[248px] flex flex-col items-start justify-end pt-0 px-0 pb-0.5 box-border">
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[25px]">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <img
              className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
              loading="lazy"
              alt=""
              src="/rectangle-164@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-0 pl-[5px] pr-1.5">
              <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
                <div className="self-stretch h-[37px] relative">
                  <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-full h-full z-[1] mq450:text-base mq450:leading-[19px]">
                    03 Vishal Singh
                  </div>
                  <img
                    className="absolute top-[23px] left-[30px] w-[69px] h-px z-[2]"
                    alt=""
                    src="/vector-45.svg"
                  />
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-0 pl-[42px] pr-[41px] text-mini text-lightgray">
                  <div className="relative leading-[120%] capitalize">{`CO - Founder & CFO`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <img
              className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
              loading="lazy"
              alt=""
              src="/rectangle-167@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-1.5">
              <div className="flex-1 flex flex-col items-start justify-start gap-[5px]">
                <div className="self-stretch h-[37px] relative">
                  <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-full h-full z-[1] mq450:text-base mq450:leading-[19px]">
                    04 Nikita Bansal
                  </div>
                  <img
                    className="absolute top-[23px] left-[30px] w-16 h-px z-[2]"
                    alt=""
                    src="/vector-44.svg"
                  />
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-base text-lightgray">
                  <div className="relative leading-[120%] capitalize inline-block min-w-[64px]">
                    Manager
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-1">
            <img
              className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
              loading="lazy"
              alt=""
              src="/rectangle-169@2x.png"
            />
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-1.5">
              <div className="flex-1 flex flex-col items-start justify-start gap-[15px]">
                <div className="self-stretch h-[27px] relative">
                  <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-[236px] h-6 mq450:text-base mq450:leading-[19px]">
                    05 Priya
                  </div>
                  <img
                    className="absolute top-[26px] left-[38px] w-[129px] h-px"
                    alt=""
                    src="/vector-43.svg"
                  />
                </div>
                <div className="self-stretch flex flex-row items-start justify-start py-0 pl-16 pr-[63px] text-base text-lightgray">
                  <div className="relative leading-[120%] capitalize inline-block min-w-[109px]">
                    COO Manager
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent4;
