import { FunctionComponent } from "react";

export type FrameComponent2Type = {
  className?: string;
};

const FrameComponent2: FunctionComponent<FrameComponent2Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[0px] left-[0px] w-full flex flex-col items-start justify-start gap-[29px] max-w-full text-center text-81xl text-black font-dm-sans ${className}`}
    >
      <div className="self-stretch [background:linear-gradient(201.28deg,_#eaeefe,_#183ec2)] flex flex-row items-start justify-start max-w-full">
        <div className="self-stretch w-[1920px] relative [background:linear-gradient(201.28deg,_#eaeefe,_#183ec2)] hidden max-w-full" />
        <div className="flex-1 flex flex-col items-start justify-start pt-8 px-[76px] pb-0 box-border relative gap-[71px] max-w-full mq925:gap-[18px] mq1350:gap-[35px] mq1350:pl-[38px] mq1350:pr-[38px] mq1350:box-border">
          <div className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-gray-400 z-[2]" />
          <div className="self-stretch flex flex-row items-start justify-start py-0 pl-[76px] pr-[78px] box-border max-w-full mq1350:pl-[38px] mq1350:pr-[39px] mq1350:box-border">
            <div className="flex-1 flex flex-col items-start justify-start max-w-full">
              <header className="self-stretch flex flex-row items-start justify-between gap-5 max-w-full">
                <img
                  className="h-[71px] w-[69px] relative object-cover z-[3]"
                  alt=""
                  src="/logodesign-2@2x.png"
                />
                <div className="w-[698px] flex flex-col items-start justify-start pt-[9px] pb-0 pl-0 pr-[29px] box-border max-w-full mq1825:w-[29px]">
                  <nav className="m-0 self-stretch shadow-[0px_2px_16px_rgba(0,_0,_0,_0.08)] rounded-31xl border-black border-[2px] border-solid overflow-hidden flex flex-row items-start justify-start py-[3px] pl-[39px] pr-9 gap-[25.1px] z-[3] text-center text-lg text-darkslategray font-inter mq1825:hidden">
                    <a className="[text-decoration:none] flex-1 relative leading-[40px] font-medium text-[inherit] whitespace-nowrap">
                      About Us
                    </a>
                    <a className="[text-decoration:none] flex-1 relative leading-[40px] font-medium text-[inherit]">
                      Portfolio
                    </a>
                    <a className="[text-decoration:none] flex-1 relative leading-[40px] font-medium text-[inherit]">
                      Services
                    </a>
                    <a className="[text-decoration:none] flex-1 relative leading-[40px] font-medium text-[inherit]">
                      Products
                    </a>
                    <a className="[text-decoration:none] flex-1 relative leading-[40px] font-medium text-[inherit] inline-block min-w-[98px] whitespace-nowrap">
                      Contact Us
                    </a>
                  </nav>
                </div>
                <div className="flex flex-col items-start justify-start pt-[26px] px-0 pb-0">
                  <img
                    className="w-[37px] h-[18px] relative z-[3]"
                    alt=""
                    src="/group-191.svg"
                  />
                </div>
              </header>
              <div className="w-[535px] flex flex-row items-start justify-start relative max-w-full">
                <img
                  className="h-[120.1px] w-[120.1px] absolute !m-[0] top-[-34px] left-[203px] object-contain z-[1]"
                  alt=""
                  src="/cursor-3@2x.png"
                />
                <div className="flex-1 flex flex-row items-start justify-end max-w-full">
                  <img
                    className="h-[76px] w-[76px] relative object-cover z-[3]"
                    alt=""
                    src="/emojistar-3@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[1312px] flex flex-col items-end justify-start gap-[26px] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-5 mq1825:flex-wrap">
              <div className="flex flex-col items-start justify-start pt-[38px] px-0 pb-0">
                <img
                  className="w-[76px] h-[76px] relative object-cover z-[3]"
                  alt=""
                  src="/emojistar-3@2x.png"
                />
              </div>
              <div className="w-[1142px] flex flex-row items-end justify-start gap-[39px] max-w-full mq925:gap-[19px] mq1350:flex-wrap">
                <div className="w-[248px] flex flex-col items-start justify-end pt-0 px-0 pb-[26px] box-border">
                  <div className="self-stretch h-[248px] relative">
                    <img
                      className="absolute top-[128px] left-[84px] w-[113px] h-[113px] object-cover z-[1]"
                      alt=""
                      src="/emojistar-5@2x.png"
                    />
                    <img
                      className="absolute top-[0px] left-[0px] w-full h-full object-cover z-[3]"
                      alt=""
                      src="/torus-1@2x.png"
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start min-w-[556px] max-w-full mq925:min-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start relative">
                    <img
                      className="w-[172.9px] h-[172.9px] absolute !m-[0] top-[40px] left-[243px] object-contain z-[1]"
                      loading="lazy"
                      alt=""
                      src="/shape@2x.png"
                    />
                    <div className="self-stretch flex flex-row items-start justify-start relative">
                      <div className="h-[680px] w-[632px] absolute !m-[0] right-[-461px] bottom-[-286px]">
                        <img
                          className="absolute top-[344px] left-[0px] w-[336px] h-[336px] object-cover z-[1]"
                          alt=""
                          src="/emojistar-1-1@2x.png"
                        />
                        <img
                          className="absolute top-[33.4px] left-[108.4px] w-[142.1px] h-[142.1px] object-contain z-[1]"
                          alt=""
                          src="/helix2-1-1@2x.png"
                        />
                        <img
                          className="absolute top-[0px] left-[148px] w-[484px] h-[484px] object-contain z-[3]"
                          alt=""
                          src="/visual@2x.png"
                        />
                      </div>
                      <h1 className="m-0 h-[300px] relative text-inherit leading-[150%] font-bold font-[inherit] inline-block z-[4] mq450:text-11xl mq450:leading-[60px] mq925:text-31xl mq925:leading-[90px]">
                        <p className="m-0">Supercharge Your</p>
                        <p className="m-0 text-midnightblue">Business|</p>
                      </h1>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 text-13xl font-inter">
                    <h2 className="m-0 relative text-inherit leading-[59px] font-medium font-[inherit] z-[3] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                      Fueling Growth Through Tailored Solutions
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5 box-border max-w-full text-left text-xl text-white font-inter">
              <div className="w-[671px] flex flex-col items-end justify-start gap-[51px] max-w-full mq925:gap-[25px]">
                <div className="self-stretch flex flex-row items-start justify-end py-0 pl-0 pr-5 box-border max-w-full">
                  <div className="flex-1 flex flex-col items-end justify-start gap-14 max-w-full mq925:gap-7">
                    <div className="rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] flex flex-row items-start justify-start py-[13px] px-[31px] whitespace-nowrap z-[3]">
                      <div className="h-[50px] w-[180px] relative rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] hidden" />
                      <div className="relative inline-block min-w-[116px] z-[4]">
                        Get in touch
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start max-w-full text-center text-smi text-black">
                      <div className="w-[594px] flex flex-row items-start justify-start relative max-w-full">
                        <img
                          className="h-[172.9px] w-[172.9px] absolute !m-[0] right-[83.1px] bottom-[-42.9px] object-contain z-[1]"
                          loading="lazy"
                          alt=""
                          src="/shape-1@2x.png"
                        />
                        <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-5 mq450:flex-wrap">
                          <img
                            className="h-[85px] w-[85px] relative object-contain z-[3]"
                            alt=""
                            src="/pyramid-2@2x.png"
                          />
                          <div className="w-[67px] flex flex-col items-start justify-start pt-[29px] px-0 pb-0 box-border">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[11px]">
                              <img
                                className="w-[66px] h-[66px] relative z-[3]"
                                alt=""
                                src="/component-3.svg"
                              />
                              <div className="self-stretch relative tracking-[-0.05em] leading-[103%] inline-block min-w-[67px] z-[3]">
                                Scroll Down
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="w-[85px] h-11 relative object-contain z-[3]"
                  alt=""
                  src="/pyramid-3@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pl-[305px] pr-0 box-border max-w-full text-117xl text-white font-inter mq450:pl-5 mq450:box-border mq925:pl-[76px] mq925:box-border mq1350:pl-[152px] mq1350:box-border">
        <h1 className="m-0 w-[2996px] relative text-inherit leading-[120%] uppercase font-black font-[inherit] flex items-center justify-center max-w-[186%] shrink-0 z-[2] mq450:text-15xl mq450:leading-[65px] mq925:text-35xl mq925:leading-[98px]">
          User Focused Value Based Data Driven
        </h1>
      </div>
    </section>
  );
};

export default FrameComponent2;
