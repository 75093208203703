import { FunctionComponent } from "react";

export type FrameComponent5Type = {
  className?: string;
};

const FrameComponent5: FunctionComponent<FrameComponent5Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[6180px] left-[166px] w-[1587.4px] flex flex-col items-end justify-start gap-9 max-w-full text-center text-13xl text-whitesmoke font-jacques-francois ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[22px] pr-5">
        <h2 className="m-0 w-[191px] relative text-inherit leading-[39px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
          Our Process
        </h2>
      </div>
      <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-5 text-lg text-white font-inter mq1825:flex-wrap mq1825:justify-center">
        <div className="h-[372.4px] w-[295px] flex flex-row items-start justify-start relative">
          <div className="h-[calc(100%_-_38.2px)] w-[calc(100%_-_57.8px)] absolute !m-[0] top-[0.01px] right-[-4.19px] bottom-[38.19px] left-[61.99px] bg-lightsteelblue [transform:_rotate(10.7deg)] [transform-origin:0_0] z-[2]" />
          <div className="h-[81px] w-[195px] absolute !m-[0] top-[63px] right-[26.28px] leading-[150%] capitalize font-semibold flex items-center [transform:_rotate(12.6deg)] [transform-origin:0_0] z-[3]">
            <span>
              <p className="m-0"> Define objectives,</p>
              <p className="m-0">target audience,</p>
              <p className="m-0">and content strategy.</p>
            </span>
          </div>
        </div>
        <div className="w-[1079.8px] flex flex-col items-start justify-start pt-[11px] px-0 pb-0 box-border max-w-full">
          <div className="self-stretch h-[401px] relative">
            <div className="absolute top-[14.62px] left-[91.66px] [background:linear-gradient(180deg,_#eaeefe,_#466f9f_96.95%)] w-[237.2px] h-[334.2px] [transform:_rotate(15.9deg)] [transform-origin:0_0]" />
            <div className="absolute top-[70.86px] left-[283.5px] [background:linear-gradient(180deg,_#edf1ff,_#aec0fa)] w-[237.2px] h-[334.2px] [transform:_rotate(-9.2deg)] [transform-origin:0_0] z-[1]" />
            <div className="absolute top-[29.31px] left-[580.35px] [background:linear-gradient(180deg,_#fff,_#c8cafd)] w-[237.2px] h-[334.2px] [transform:_rotate(7.9deg)] [transform-origin:0_0] z-[3]" />
            <div className="absolute top-[69.27px] left-[755.4px] [background:linear-gradient(180deg,_#91d1f4,_#fff_86.25%)] w-[237.2px] h-[334.2px] [transform:_rotate(-17deg)] [transform-origin:0_0] z-[6]" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent5;
