import { FunctionComponent } from "react";

export type GroupComponentType = {
  className?: string;
};

const GroupComponent: FunctionComponent<GroupComponentType> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[10103px] left-[0px] bg-mediumblue-100 w-full flex flex-col items-start justify-start pt-20 px-[151px] pb-10 box-border gap-[47px] max-w-full z-[2] text-left text-xl text-whitesmoke font-inter mq925:gap-[23px] mq925:pl-[37px] mq925:pr-[37px] mq925:box-border mq1350:pl-[75px] mq1350:pr-[75px] mq1350:box-border ${className}`}
    >
      <div className="w-[1920px] h-[446px] relative bg-mediumblue-100 hidden max-w-full" />
      <div className="w-[1580px] flex flex-row items-start justify-start py-0 px-px box-border max-w-full">
        <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-5 mq1825:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[22px] min-w-[538px] max-w-full mq925:min-w-full mq1825:flex-1">
            <img
              className="w-[69px] h-[71px] relative object-contain z-[1]"
              loading="lazy"
              alt=""
              src="/mask-group@2x.png"
            />
            <div className="relative leading-[150%] capitalize z-[1] mq450:text-base mq450:leading-[24px]">
              <p className="m-0">
                With our collective expertise, we turn ideas into reality.
              </p>
              <p className="m-0">
                Whether you're a startup or an established brand,
              </p>
              <p className="m-0">
                we're here to elevate your online presence and help
              </p>
              <p className="m-0">you achieve your goals.</p>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[100px] min-w-[888px] max-w-full mq450:gap-[25px] mq925:gap-[50px] mq1350:flex-wrap mq1350:min-w-full mq1825:flex-1">
            <div className="relative leading-[150%] font-medium z-[1] mq450:text-base mq450:leading-[24px]">
              <p className="m-0 text-lavender">How We Can Help</p>
              <p className="m-0">Help</p>
              <p className="m-0">Privacy Policy</p>
              <p className="m-0">Returns Policy</p>
              <p className="m-0">Terms and Conditions</p>
            </div>
            <div className="relative leading-[150%] font-medium text-black z-[1] mq450:text-base mq450:leading-[24px]">
              <p className="m-0">About Us</p>
              <p className="m-0">Who We Are</p>
              <p className="m-0">Contact Us</p>
            </div>
            <div className="w-[360px] flex flex-col items-start justify-start gap-[22px] max-w-full text-lavender">
              <div className="relative leading-[150%] font-medium z-[1] mq450:text-base mq450:leading-[24px]">
                Follow us on socials
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-5 mq450:flex-wrap">
                <img
                  className="h-[75px] w-[75px] relative object-cover min-h-[75px] z-[1]"
                  loading="lazy"
                  alt=""
                  src="/group-234@2x.png"
                />
                <img
                  className="h-[75px] w-[75px] relative object-cover min-h-[75px] z-[1]"
                  loading="lazy"
                  alt=""
                  src="/group-233@2x.png"
                />
                <div className="h-[75px] flex-1 relative min-w-[49px]">
                  <img
                    className="absolute top-[0px] left-[0px] w-full h-full object-cover z-[1]"
                    alt=""
                    src="/group-232@2x.png"
                  />
                  <img
                    className="absolute top-[22px] left-[22px] w-8 h-8 object-contain z-[3]"
                    alt=""
                    src="/instagram-2@2x.png"
                  />
                </div>
                <img
                  className="h-[75px] w-[75px] relative object-cover min-h-[75px] z-[1]"
                  loading="lazy"
                  alt=""
                  src="/group-231@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start gap-[37px] max-w-full text-white mq925:gap-[18px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full">
          <img
            className="w-[1369.5px] relative max-h-full max-w-full z-[1]"
            alt=""
            src="/vector-50-1.svg"
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-5 mq925:flex-wrap mq925:justify-center">
          <div className="flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
            <div className="relative capitalize z-[1] mq450:text-base">
              Copyright 2024 © Frame digital
            </div>
          </div>
          <div className="relative capitalize z-[1] mq450:text-base">{`Privacy & Policy`}</div>
        </div>
      </div>
    </section>
  );
};

export default GroupComponent;
