import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type FrameComponent3Type = {
  className?: string;
  rectangle191?: string;
  nausheen?: string;
  seniorManager?: string;

  /** Style props */
  propLeft?: CSSProperties["left"];
  propMinWidth?: CSSProperties["minWidth"];
};

const FrameComponent3: FunctionComponent<FrameComponent3Type> = ({
  className = "",
  rectangle191,
  nausheen,
  propLeft,
  seniorManager,
  propMinWidth,
}) => {
  const vectorIconStyle: CSSProperties = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const seniorManagerStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  return (
    <div
      className={`h-[405px] w-[289px] flex flex-col items-start justify-start gap-[4.5px] text-left text-xl text-whitesmoke font-jacques-francois ${className}`}
    >
      <img
        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
        loading="lazy"
        alt=""
        src={rectangle191}
      />
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-1.5">
        <div className="h-[37px] flex-1 relative">
          <div className="absolute top-[0px] left-[0px] leading-[120%] capitalize whitespace-pre-wrap flex items-center w-full h-full mq450:text-base mq450:leading-[19px]">
            {nausheen}
          </div>
          <img
            className="absolute top-[24px] left-[59px] w-[87px] h-px z-[1]"
            alt=""
            src="/vector-39.svg"
            style={vectorIconStyle}
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[22px] text-base text-lightgray">
        <div
          className="relative leading-[120%] capitalize inline-block min-w-[117px]"
          style={seniorManagerStyle}
        >
          {seniorManager}
        </div>
      </div>
    </div>
  );
};

export default FrameComponent3;
