import { FunctionComponent } from "react";

export type FrameComponent1Type = {
  className?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[2204px] left-[0px] w-full flex flex-row items-end justify-start py-0 px-0 box-border gap-[219.3px] max-w-full text-left text-13xl text-darkslategray font-jacques-francois mq450:gap-[27px] mq925:gap-[55px] mq1350:gap-[110px] mq1825:flex-wrap ${className}`}
    >
      <div className="w-[1920px] bg-white flex flex-col items-end justify-start pt-[72px] pb-[166.4px] pl-[152px] pr-[153px] box-border gap-[310px] shrink-0 [debug_commit:bf4bc93] max-w-full mq925:gap-[77px] mq925:pl-[38px] mq925:pr-[38px] mq925:box-border mq1350:gap-[155px] mq1350:pl-[76px] mq1350:pr-[76px] mq1350:box-border">
        <div className="w-[1920px] h-[1925px] relative bg-white hidden max-w-full" />
        <div className="w-[959px] flex flex-row items-start justify-center max-w-full">
          <div className="flex flex-col items-start justify-start gap-[26px] max-w-full">
            <div className="flex flex-row items-start justify-start py-0 px-px text-center">
              <h2 className="m-0 relative text-inherit leading-[50px] capitalize font-normal font-[inherit] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                Our Portfolio
              </h2>
            </div>
            <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[26px] gap-4 text-33xl font-dm-sans">
              <h1 className="m-0 relative text-inherit leading-[150%] capitalize font-medium font-[inherit] z-[1] mq450:text-12xl mq450:leading-[47px] mq925:text-23xl mq925:leading-[62px]">
                <p className="m-0">Our user-centered design</p>
                <p className="m-0">encourages productivity</p>
                <p className="m-0">and boosts revenue.</p>
              </h1>
              <div className="relative text-lg leading-[150%] capitalize font-inter z-[1]">
                We don't do cookie-cutter solutions
              </div>
            </div>
            <div className="rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] flex flex-row items-start justify-start py-[13px] px-8 relative whitespace-nowrap z-[1] text-xl text-white font-inter">
              <div className="h-[50px] w-[180px] relative rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] hidden z-[0]" />
              <div className="relative inline-block min-w-[116px] z-[1]">
                Get in touch
              </div>
              <img
                className="h-[121.2px] w-[121.2px] absolute !m-[0] right-[-99.2px] bottom-[-96.2px] object-contain z-[2]"
                alt=""
                src="/cursor-2@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start max-w-full text-center">
          <div className="self-stretch flex flex-col items-end justify-start max-w-full">
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <div className="w-[1196px] flex flex-row items-start justify-start gap-[50px] max-w-full mq925:gap-[25px] mq1825:flex-wrap">
                <h2 className="m-0 h-[39px] w-[191px] relative text-inherit leading-[120px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 [transform:_rotate(-90deg)] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                  Our Services
                </h2>
                <h1 className="m-0 h-[190px] w-[1107px] relative text-33xl leading-[150%] capitalize font-medium font-dm-sans text-left flex items-center shrink-0 max-w-full z-[1] mq450:text-12xl mq450:leading-[47px] mq925:text-23xl mq925:leading-[62px]">
                  <span className="w-full">
                    <p className="m-0">
                      As a tight-knit team of experts, we create memorable and
                      emotional websites,
                    </p>
                    <p className="m-0">digital experiences, and native apps.</p>
                  </span>
                </h1>
              </div>
            </div>
            <div className="w-[1133.5px] flex flex-col items-start justify-start gap-[23.9px] max-w-full mt-[-1px] text-left text-[transparent] font-inter">
              <div className="self-stretch flex flex-row items-start justify-end">
                <div className="h-[22.1px] w-[180px] relative">
                  <img
                    className="absolute top-[0px] left-[105px] w-[75px] h-[22.1px] z-[1]"
                    alt=""
                    src="/arrow-4.svg"
                  />
                  <img
                    className="absolute top-[0px] left-[0px] w-[75px] h-0 object-contain z-[1]"
                    alt=""
                    src="/arrow-5.svg"
                  />
                </div>
              </div>
              <div className="flex flex-row items-start justify-start relative">
                <h2 className="m-0 relative text-inherit leading-[120px] capitalize font-normal font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                  UI/UX Design
                </h2>
                <div className="absolute !m-[0] bottom-[-30px] left-[60px] text-62xl leading-[122px] capitalize font-kosugi inline-block [-webkit-text-stroke:2px_#000] min-w-[81px] z-[3] mq450:text-5xl mq450:leading-[49px] mq925:text-21xl mq925:leading-[73px]">
                  02
                </div>
              </div>
            </div>
          </div>
          <div className="w-[1452.3px] flex flex-row items-start justify-start gap-[137px] max-w-full mt-[-26px] text-left text-[transparent] font-inter mq450:gap-[17px] mq925:gap-[34px] mq1350:gap-[68px] mq1825:flex-wrap">
            <div className="flex-1 flex flex-row items-start justify-start gap-[29.9px] min-w-[756px] max-w-full mq1350:flex-wrap mq1350:min-w-full">
              <div className="flex-1 flex flex-col items-start justify-start pt-0.5 px-0 pb-0 box-border min-w-[275px] max-w-full">
                <div className="self-stretch h-[556.6px] flex flex-col items-start justify-start gap-[54px] max-w-full mq450:gap-[27px]">
                  <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5">
                    <h2 className="m-0 relative text-inherit leading-[41px] capitalize font-normal font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] shrink-0 [debug_commit:bf4bc93] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                      Development
                    </h2>
                  </div>
                  <div className="self-stretch flex-1 flex flex-row items-start justify-start relative max-w-full text-62xl font-kosugi">
                    <img
                      className="h-[461.6px] flex-1 relative max-w-full overflow-hidden object-cover shrink-0 [debug_commit:bf4bc93] z-[1]"
                      loading="lazy"
                      alt=""
                      src="/rectangle-145@2x.png"
                    />
                    <div className="w-[81px] absolute !m-[0] top-[-66px] left-[143px] leading-[122px] capitalize inline-block [-webkit-text-stroke:2px_#000] z-[2] mq450:text-5xl mq450:leading-[49px] mq925:text-21xl mq925:leading-[73px]">
                      01
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="h-[461.6px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[276px] z-[2]"
                loading="lazy"
                alt=""
                src="/rectangle-146@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start pt-9 px-0 pb-0 box-border min-w-[275px] max-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[54px] max-w-full mq450:gap-[27px]">
                  <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
                    <h2 className="m-0 relative text-inherit leading-[40px] capitalize font-normal font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] shrink-0 [debug_commit:bf4bc93] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                      Logo design
                    </h2>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start relative max-w-full text-62xl font-kosugi">
                    <img
                      className="h-[461.6px] flex-1 relative max-w-full overflow-hidden object-cover shrink-0 [debug_commit:bf4bc93] z-[1]"
                      loading="lazy"
                      alt=""
                      src="/rectangle-147@2x.png"
                    />
                    <div className="absolute !m-[0] top-[-66px] left-[143px] leading-[122px] capitalize inline-block [-webkit-text-stroke:2px_#000] min-w-[81px] z-[2] mq450:text-5xl mq450:leading-[49px] mq925:text-21xl mq925:leading-[73px]">
                      03
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[152px] flex flex-col items-start justify-start pt-[62px] px-0 pb-0 box-border">
              <div className="self-stretch flex flex-col items-start justify-start">
                <h2 className="m-0 h-[41px] relative text-inherit leading-[120px] capitalize font-normal font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex items-center shrink-0 [debug_commit:bf4bc93] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                  Marketing
                </h2>
                <div className="self-stretch flex flex-row items-start justify-start py-0 pl-9 pr-[35px] mt-[-12px] text-62xl font-kosugi">
                  <div className="flex-1 flex flex-row items-start justify-start relative">
                    <img
                      className="h-[461.6px] w-[366.6px] absolute !m-[0] bottom-[-405.6px] left-[-143px] object-cover z-[1]"
                      loading="lazy"
                      alt=""
                      src="/rectangle-148@2x.png"
                    />
                    <div className="relative leading-[122px] capitalize inline-block [-webkit-text-stroke:2px_#000] min-w-[81px] shrink-0 [debug_commit:bf4bc93] z-[2] mq450:text-5xl mq450:leading-[49px] mq925:text-21xl mq925:leading-[73px]">
                      04
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[635px] w-[367.5px] flex flex-col items-start justify-start max-w-full shrink-0">
        <div className="self-stretch h-[461.6px] relative shadow-[0px_6px_12px_rgba(0,_0,_0,_0.25)] bg-gainsboro-200 shrink-0 [debug_commit:bf4bc93]" />
      </div>
    </section>
  );
};

export default FrameComponent1;
