import { FunctionComponent } from "react";
import GroupComponent1 from "./GroupComponent1";

export type FrameComponent6Type = {
  className?: string;
};

const FrameComponent6: FunctionComponent<FrameComponent6Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[5268px] left-[144px] w-[1572px] flex flex-col items-end justify-start gap-[46px] max-w-full text-center text-13xl text-whitesmoke font-jacques-francois ${className}`}
    >
      <div className="w-[1278px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <h2 className="m-0 w-[234px] relative text-inherit leading-[46px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
          Why Choose Us
        </h2>
      </div>
      <div className="self-stretch flex flex-row items-end justify-between max-w-full gap-5 font-inter mq1825:flex-wrap mq1825:justify-center">
        <div className="h-[535px] w-[615.6px] flex flex-col items-start justify-start min-w-[615.6px] max-w-full mq925:min-w-full mq1825:flex-1">
          <div className="self-stretch h-[487.1px] flex flex-row items-start justify-start relative">
            <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
              <img
                className="absolute top-[0px] left-[99.6px] w-[437.6px] h-[437.6px] object-contain"
                alt=""
                src="/illustration3x-1@2x.png"
              />
              <img
                className="absolute top-[10.2px] left-[493.8px] w-[121.8px] h-[123.5px] object-contain z-[1]"
                alt=""
                src="/layer2-1@2x.png"
              />
              <img
                className="absolute top-[287.8px] left-[0px] w-[198.4px] h-[199.3px] object-contain z-[1]"
                alt=""
                src="/layer3-1@2x.png"
              />
              <img
                className="absolute top-[300.6px] left-[452.1px] w-[163.5px] h-[174.2px] object-contain z-[1]"
                loading="lazy"
                alt=""
                src="/blob-1@2x.png"
              />
              <img
                className="absolute top-[0px] left-[34.9px] w-[97.1px] h-[144.7px] object-contain z-[1]"
                alt=""
                src="/blob-2@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="w-[756px] flex flex-row items-start justify-start gap-[50px] min-w-[756px] max-w-full mq925:flex-wrap mq925:min-w-full mq1825:flex-1">
          <div className="flex-1 flex flex-col items-start justify-start gap-[50px] min-w-[229px] max-w-full">
            <div className="self-stretch shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 flex flex-col items-end justify-start py-[37px] px-[22px] box-border gap-5 max-w-full">
              <div className="w-[353px] h-[291px] relative shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 hidden max-w-full" />
              <div className="self-stretch flex flex-row items-start justify-center py-0 pl-px pr-0">
                <img
                  className="h-[89px] w-[98px] relative object-cover z-[1]"
                  alt=""
                  src="/money-stack-1@2x.png"
                />
              </div>
              <div className="self-stretch flex flex-col items-end justify-start gap-3">
                <div className="self-stretch flex flex-row items-start justify-end py-0 pl-[33px] pr-8">
                  <h2 className="m-0 relative text-inherit leading-[42px] capitalize font-normal font-[inherit] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                    Affordable Price
                  </h2>
                </div>
                <div className="relative text-lg leading-[150%] capitalize z-[1]">
                  <p className="m-0">We provide websites to all startups</p>
                  <p className="m-0">at very affordable prices.</p>
                </div>
              </div>
            </div>
            <GroupComponent1
              checkmark="/checkmark@2x.png"
              industryStandard="Industry Standard"
              weEnsureOurWorkMeets="We ensure our work meets"
              industryStandards="industry standards."
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[50px] min-w-[229px] max-w-full">
            <GroupComponent1
              propPadding="0px 20px 0px 22px"
              checkmark="/wallet-2@2x.png"
              propHeight="89px"
              propWidth="103px"
              propPadding1="0px 0px 0px 1px"
              industryStandard="Personal Connection"
              propHeight1="39px"
              weEnsureOurWorkMeets="We Build a Personal Connection"
              industryStandards="with All company Owners"
            />
            <div className="self-stretch shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 flex flex-col items-start justify-start pt-[37px] px-[29px] pb-9 box-border gap-5 max-w-full">
              <div className="w-[353px] h-[291px] relative shadow-[11px_11px_13px_5px_rgba(0,_0,_0,_0.25)] bg-gray-200 hidden max-w-full" />
              <div className="self-stretch flex flex-row items-start justify-center">
                <img
                  className="h-[85px] w-[79px] relative object-cover z-[1]"
                  alt=""
                  src="/thumbs-up@2x.png"
                />
              </div>
              <div className="self-stretch flex flex-col items-end justify-start gap-3">
                <div className="self-stretch flex flex-row items-start justify-end py-0 pl-16 pr-[63px]">
                  <div className="relative leading-[47px] capitalize z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                    100% Trust
                  </div>
                </div>
                <div className="relative text-lg leading-[150%] capitalize z-[1]">
                  <p className="m-0">We give 100% trust to our clients</p>
                  <p className="m-0">and their startups.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent6;
