import { FunctionComponent } from "react";

export type FrameComponentType = {
  className?: string;
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  className = "",
}) => {
  return (
    <section
      className={`absolute top-[4275px] left-[152px] flex flex-row items-end justify-start gap-[50px] max-w-full text-left text-xl text-black font-inter mq1825:flex-wrap ${className}`}
    >
      <div className="h-[497px] w-[348px] flex flex-col items-start justify-start max-w-full text-center text-13xl text-whitesmoke font-jacques-francois">
        <div className="w-[254px] flex flex-col items-start justify-start py-0 pl-0 pr-5 box-border gap-14">
          <h2 className="m-0 w-[206px] h-[39px] relative text-inherit leading-[120px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
            Our Products
          </h2>
          <div className="self-stretch flex flex-col items-start justify-start gap-[38px] text-left text-xl text-white font-inter">
            <div className="flex flex-col items-start justify-start gap-[5px]">
              <div className="h-[21px] relative leading-[150%] capitalize font-medium text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] flex items-center shrink-0 mq450:text-base mq450:leading-[24px]">
                our software
              </div>
              <div className="flex flex-row items-start justify-start py-0 pl-px pr-[3px]">
                <img
                  className="h-0.5 w-[125.5px] relative"
                  alt=""
                  src="/vector-51-1.svg"
                />
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[31px]">
              <div className="self-stretch relative leading-[150%] capitalize font-medium mq450:text-base mq450:leading-[24px]">
                <p className="m-0">security and</p>
                <p className="m-0">computing solutions</p>
              </div>
              <div className="relative leading-[150%] capitalize font-medium inline-block min-w-[37px] mq450:text-base mq450:leading-[24px]">
                ATL
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[402px] w-[527px] flex flex-row items-end justify-start bg-[url('/public/rectangle-196@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">
        <img
          className="w-[527px] relative max-h-full object-cover hidden max-w-full"
          alt=""
          src="/rectangle-196@2x.png"
        />
        <div className="flex-1 [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] flex flex-row items-start justify-start pt-[50px] px-5 pb-5 box-border max-w-full z-[1]">
          <div className="h-[100px] w-[527px] relative [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] hidden max-w-full" />
          <div className="relative leading-[150%] capitalize font-medium z-[2] mq450:text-base mq450:leading-[24px]">
            Restaurant management System
          </div>
        </div>
      </div>
      <div className="w-[793px] flex flex-col items-end justify-start gap-[75px] max-w-full">
        <div className="w-[488px] h-0 flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
          <img className="h-0 w-[180px] relative" alt="" src="/group-219.svg" />
        </div>
        <div className="self-stretch flex flex-row items-start justify-center gap-[50px] max-w-full mq925:flex-wrap">
          <div className="flex-1 flex flex-row items-end justify-start bg-[url('/public/rectangle-195@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[343px] min-h-[402px] max-w-full mq450:min-w-full mq925:min-h-[auto]">
            <img
              className="w-[527px] relative max-h-full object-cover hidden max-w-full"
              alt=""
              src="/rectangle-195@2x.png"
            />
            <div className="flex-1 [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] flex flex-row items-start justify-start pt-[50px] px-5 pb-5 box-border max-w-full z-[1]">
              <div className="h-[100px] w-[527px] relative [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] hidden max-w-full" />
              <div className="relative leading-[150%] capitalize font-medium z-[2] mq450:text-base mq450:leading-[24px]">
                School management System
              </div>
            </div>
          </div>
          <div className="w-[216px] flex flex-col items-start justify-start py-0 px-0 box-border min-w-[216px] mq925:flex-1">
            <img
              className="w-[527px] relative max-h-full object-cover max-w-[244%] shrink-0"
              loading="lazy"
              alt=""
              src="/rectangle-197@2x.png"
            />
            <div className="w-[527px] [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] flex flex-row items-start justify-start pt-[50px] px-5 pb-5 box-border max-w-[244%] shrink-0 z-[1] mt-[-100px]">
              <div className="h-[100px] w-[527px] relative [background:linear-gradient(0deg,_#f4f4f4,_rgba(221,_221,_221,_0.92)_26.45%,_rgba(220,_220,_220,_0.74)_40.26%,_rgba(171,_171,_171,_0))] hidden max-w-full" />
              <div className="w-[279px] relative leading-[150%] capitalize font-medium flex items-center z-[2] mq450:text-base mq450:leading-[24px]">
                Billing and POS Management
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
