import { FunctionComponent } from "react";
import FrameComponent8 from "../components/FrameComponent8";
import FrameComponent7 from "../components/FrameComponent7";
import FrameComponent6 from "../components/FrameComponent6";
import FrameComponent5 from "../components/FrameComponent5";
import FrameComponent4 from "../components/FrameComponent4";
import FrameComponent3 from "../components/FrameComponent3";
import GroupComponent from "../components/GroupComponent";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";

const FrameDigital: FunctionComponent = () => {
  return (
    <div className="w-full h-[10549px] relative bg-gray-300 overflow-hidden leading-[normal] tracking-[normal] text-center text-lg text-dimgray-100 font-inter mq1350:h-auto mq1350:min-h-[10549]">
      <footer className="absolute top-[8748px] left-[0px] shadow-[0px_4px_24px_-1px_rgba(0,_0,_0,_0.2)] [backdrop-filter:blur(40px)] [background:linear-gradient(109.5deg,_rgba(217,_217,_217,_0.12),_rgba(217,_217,_217,_0.16)_46.97%,_rgba(217,_217,_217,_0)_90.96%)] w-full flex flex-col items-start justify-start py-28 px-[152px] box-border gap-[249px] max-w-full text-center text-13xl text-whitesmoke font-jacques-francois mq450:gap-[31px] mq925:gap-[62px] mq925:pl-[38px] mq925:pr-[38px] mq925:box-border mq1350:gap-[124px] mq1350:pl-[76px] mq1350:pr-[76px] mq1350:box-border">
        <div className="w-[1920px] h-[1355px] relative shadow-[0px_4px_24px_-1px_rgba(0,_0,_0,_0.2)] [backdrop-filter:blur(40px)] [background:linear-gradient(109.5deg,_rgba(217,_217,_217,_0.12),_rgba(217,_217,_217,_0.16)_46.97%,_rgba(217,_217,_217,_0)_90.96%)] hidden max-w-full z-[0]" />
        <div className="w-[1240px] flex flex-row items-start justify-between max-w-full gap-5 mq1350:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-16 min-w-[326px] max-w-full mq450:gap-8 mq1350:flex-1">
            <h2 className="m-0 relative text-inherit leading-[120%] capitalize font-normal font-[inherit] z-[2] mq450:text-lgi mq450:leading-[23px] mq925:text-7xl mq925:leading-[31px]">
              What Our Clients Say
            </h2>
            <div className="flex flex-row items-start justify-start py-0 px-0.5 text-left text-lg font-inter">
              <div className="relative leading-[150%] capitalize z-[2]">
                <p className="m-0">We supply clients across the</p>
                <p className="m-0">entire globe with improved</p>
                <p className="m-0">network connections.</p>
              </div>
            </div>
          </div>
          <div className="w-[591px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border min-w-[591px] max-w-full text-left font-inter mq1350:flex-1 mq1350:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[164px] mq450:gap-[41px] mq925:gap-[82px]">
              <h2 className="m-0 relative text-inherit leading-[120%] capitalize font-medium font-[inherit] z-[2] mq450:text-lgi mq450:leading-[23px] mq925:text-7xl mq925:leading-[31px]">
                <p className="m-0">Awsmd focuses on the end-user and</p>
                <p className="m-0">helps improve retention. They excel</p>
                <p className="m-0">in user experience and big-picture</p>
                <p className="m-0">thinking.</p>
              </h2>
              <div className="self-stretch flex flex-row items-start justify-center py-0 pl-[115px] pr-5 text-center mq450:pl-5 mq450:box-border">
                <div className="flex flex-col items-start justify-start gap-2.5">
                  <h2 className="m-0 relative text-inherit leading-[120%] capitalize font-normal font-[inherit] z-[2] mq450:text-lgi mq450:leading-[23px] mq925:text-7xl mq925:leading-[31px]">
                    Sanjana singh
                  </h2>
                  <div className="relative text-lg leading-[150%] capitalize text-darkgray text-left z-[2]">{`Founder & CEO, Mountabu School`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FrameComponent8 />
        <div className="w-[1519px] flex flex-col items-start justify-start max-w-full">
          <div className="flex flex-row items-start justify-start py-0 px-0.5">
            <h2 className="m-0 w-[217px] relative text-inherit leading-[120%] capitalize font-normal font-[inherit] inline-block z-[2] mq450:text-lgi mq450:leading-[23px] mq925:text-7xl mq925:leading-[31px]">
              Have An idea?
            </h2>
          </div>
          <FrameComponent7 />
        </div>
      </footer>
      <img
        className="absolute top-[944px] left-[126px] w-[261px] h-[261px] object-contain z-[1]"
        loading="lazy"
        alt=""
        src="/object@2x.png"
      />
      <img
        className="absolute top-[931px] left-[1103px] w-[147.3px] h-[143.8px] object-contain z-[1]"
        alt=""
        src="/object-1@2x.png"
      />
      <img
        className="absolute top-[1042px] left-[702px] w-[147.3px] h-[143.8px] object-contain"
        loading="lazy"
        alt=""
        src="/object-2@2x.png"
      />
      <img
        className="absolute top-[991px] left-[2272px] w-[124px] h-[124px] object-contain"
        loading="lazy"
        alt=""
        src="/object-3@2x.png"
      />
      <img
        className="absolute top-[955px] left-[3216px] w-[349px] h-[349px] object-contain"
        loading="lazy"
        alt=""
        src="/object-4@2x.png"
      />
      <div className="absolute top-[2108px] left-[152px] w-[581px] h-[726px]">
        <img
          className="absolute top-[30px] left-[0px] w-[493px] h-[666px] object-cover z-[1]"
          alt=""
          src="/rectangle-144@2x.png"
        />
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[43px] max-h-full w-[538px] object-cover z-[2]"
          alt=""
          src="/rectangle-143@2x.png"
        />
      </div>
      <img
        className="absolute top-[2067px] left-[238px] w-[599px] h-[808px] object-cover z-[3]"
        loading="lazy"
        alt=""
        src="/rectangle-142@2x.png"
      />
      <section className="absolute top-[1292px] left-[152px] w-[1509px] h-[827.1px] max-w-full text-center text-13xl text-whitesmoke font-jacques-francois mq1350:h-auto mq1350:min-h-[827.0999999999999]">
        <div className="absolute top-[0px] left-[0px] w-full flex flex-row items-start justify-between max-w-full gap-5 h-full mq1350:flex-wrap">
          <div className="w-[680px] flex flex-col items-start justify-start pt-[123px] px-0 pb-0 box-border min-w-[680px] max-w-full mq925:min-w-full mq1350:flex-1">
            <div className="self-stretch flex flex-row items-start justify-start gap-[50px] max-w-full mq925:flex-wrap">
              <h2 className="m-0 h-[39px] w-[143px] relative text-inherit leading-[120px] capitalize font-normal font-[inherit] flex items-center justify-center shrink-0 [transform:_rotate(-90deg)] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                About us
              </h2>
              <div className="flex flex-col items-start justify-start gap-[52px] max-w-full text-left text-lg font-inter">
                <div className="relative leading-[150%] capitalize">
                  <p className="m-0">
                    Welcome to Frame Digital! We're a dedicated team offering
                  </p>
                  <p className="m-0">{`top-notch digital solutions including logo design, UI/UX design, `}</p>
                  <p className="m-0">development, marketing, and SEO.</p>
                  <p className="m-0">&nbsp;</p>
                  <p className="m-0">
                    With our collective expertise, we turn ideas into reality.
                    Whether
                  </p>
                  <p className="m-0">
                    you're a startup or an established brand, we're here to
                    elevate your
                  </p>
                  <p className="m-0">
                    online presence and help you achieve your goals.
                  </p>
                  <p className="m-0">&nbsp;</p>
                  <p className="m-0">
                    At Frame Digital, we prioritize quality, innovation, and
                    client
                  </p>
                  <p className="m-0">
                    satisfaction. Let's work together to bring your vision to
                    life.
                  </p>
                </div>
                <div className="flex flex-row items-start justify-start py-0 px-[3px] text-xl text-white">
                  <div className="rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] flex flex-row items-start justify-start py-[13px] px-8 whitespace-nowrap">
                    <div className="h-[50px] w-[180px] relative rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] hidden" />
                    <div className="relative inline-block min-w-[116px] z-[1]">
                      Get in touch
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[827.1px] w-[597px] flex flex-row items-end justify-start min-w-[597px] max-w-full mq925:min-w-full mq1350:flex-1">
            <div className="self-stretch w-[597px] flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch h-[608.2px] relative">
                <div className="absolute top-[18px] left-[451px] [filter:blur(146px)] rounded-[50%] bg-mediumblue-200 w-[146px] h-[114px]" />
                <div className="absolute top-[0px] left-[0px] [filter:blur(146px)] rounded-[50%] bg-mediumblue-200 w-[91px] h-[71px]" />
                <div className="absolute w-[calc(100%_-_10.5px)] top-[0px] right-[3.5px] left-[7px] h-[608.2px] z-[2]">
                  <div className="absolute top-[175px] left-[244px] [filter:blur(161px)] rounded-[50%] bg-mediumblue-200 w-[227px] h-[177px]" />
                  <div className="absolute top-[283px] left-[452px] [filter:blur(167px)] rounded-[50%] bg-mediumblue-200 w-[129px] h-[101px] z-[1]" />
                  <div className="absolute top-[35px] left-[161px] [filter:blur(146px)] rounded-[50%] bg-mediumblue-200 w-[173px] h-[135px]" />
                  <img
                    className="absolute top-[400.5px] left-[187.8px] w-[234.2px] h-[207.7px] object-contain"
                    alt=""
                    src="/torus-2@2x.png"
                  />
                  <img
                    className="absolute top-[211.3px] left-[369.2px] w-[105.6px] h-[105.6px] object-contain"
                    alt=""
                    src="/pyramid-4@2x.png"
                  />
                  <img
                    className="absolute top-[168.6px] left-[269px] w-[161px] h-[162px] object-contain z-[1]"
                    alt=""
                    src="/cubehelix-1@2x.png"
                  />
                  <img
                    className="absolute top-[310.1px] left-[203.4px] w-[144.9px] h-[146.8px] object-contain z-[2]"
                    alt=""
                    src="/helix2-1@2x.png"
                  />
                  <img
                    className="absolute top-[308.6px] left-[336.9px] w-[120px] h-[117.5px] object-contain z-[3]"
                    alt=""
                    src="/helix2-2@2x.png"
                  />
                  <img
                    className="absolute top-[29.6px] left-[146px] w-[174px] h-44 object-contain z-[2]"
                    alt=""
                    src="/cubehelix-2@2x.png"
                  />
                  <img
                    className="absolute top-[0.3px] left-[447.1px] w-[139.4px] h-[139.4px] object-contain"
                    loading="lazy"
                    alt=""
                    src="/cubehelix-3@2x.png"
                  />
                  <img
                    className="absolute top-[296.6px] left-[488.7px] w-[69.7px] h-[69.7px] object-contain"
                    alt=""
                    src="/cubehelix-4@2x.png"
                  />
                  <img
                    className="absolute top-[0px] left-[0px] w-[69.7px] h-[69.7px] object-contain"
                    alt=""
                    src="/cubehelix-5@2x.png"
                  />
                </div>
              </div>
            </div>
            <img
              className="h-[22.1px] w-[75px] relative ml-[-595px]"
              alt=""
              src="/arrow-1.svg"
            />
          </div>
        </div>
        <img
          className="absolute top-[805px] left-[809px] w-[75px] h-0 object-contain"
          alt=""
          src="/arrow-3.svg"
        />
      </section>
      <h2 className="m-0 absolute top-[3322px] left-[1893.8px] text-13xl leading-[120px] capitalize font-normal font-[inherit] text-transparent !bg-clip-text [background:linear-gradient(90deg,_#1029ca,_#535edc)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] text-left flex items-center w-16 h-[49px] z-[1] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
        SEO
      </h2>
      <img
        className="absolute top-[3421px] left-[1741.8px] w-[367.5px] h-[461.6px] object-cover z-[1]"
        loading="lazy"
        alt=""
        src="/rectangle-149@2x.png"
      />
      <div className="absolute top-[3355px] left-[1884.8px] text-62xl leading-[122px] capitalize font-kosugi text-[transparent] text-left [-webkit-text-stroke:2px_#000] z-[2] mq450:text-5xl mq450:leading-[49px] mq925:text-21xl mq925:leading-[73px]">
        05
      </div>
      <FrameComponent6 />
      <img
        className="absolute top-[6452px] left-[240px] w-[201.8px] h-[199.9px] object-contain z-[3]"
        alt=""
        src="/object-5@2x.png"
      />
      <div className="absolute top-[6280.6px] left-[422.4px] w-[274.1px] h-[359.3px] flex flex-col items-end justify-start pt-[42.4px] px-[23px] pb-[42px] box-border text-white">
        <img
          className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-contain z-[1]"
          alt=""
          src="/rectangle-156@2x.png"
        />
        <div className="h-[108px] absolute !m-[0] right-[40.5px] bottom-[34.18px] leading-[150%] capitalize font-semibold flex items-center [transform:_rotate(-7.7deg)] [transform-origin:0_0] z-[2]">
          <span>
            <p className="m-0">Create wireframes</p>
            <p className="m-0">and mockups for the</p>
            <p className="m-0">website layout and</p>
            <p className="m-0">aesthetics.</p>
          </span>
        </div>
        <img
          className="w-[162px] h-[125px] relative object-contain z-[2]"
          alt=""
          src="/object-6@2x.png"
        />
      </div>
      <div className="absolute top-[6467.01px] left-[717.81px] leading-[150%] capitalize font-semibold text-white flex items-center [transform:_rotate(17.1deg)] [transform-origin:0_0] z-[2]">
        <span>
          <p className="m-0">Code the website using</p>
          <p className="m-0">appropriate technologies</p>
          <p className="m-0"> and frameworks.</p>
        </span>
      </div>
      <img
        className="absolute top-[6349px] left-[752px] w-[189.1px] h-[189.1px] object-contain z-[3]"
        loading="lazy"
        alt=""
        src="/object-7@2x.png"
      />
      <img
        className="absolute top-[6478px] left-[1025px] w-[193px] h-[184px] object-contain z-[2]"
        loading="lazy"
        alt=""
        src="/object-8@2x.png"
      />
      <div className="absolute top-[6416.74px] left-[979.4px] leading-[150%] capitalize font-semibold [transform:_rotate(-10.5deg)] [transform-origin:0_0] z-[3]">
        <p className="m-0"> Generate and optimize</p>
        <p className="m-0">content including text,</p>
        <p className="m-0">images, and multimedia.</p>
      </div>
      <img
        className="absolute top-[6317.7px] left-[1232px] w-[157.3px] h-[157.3px] object-contain z-[4]"
        alt=""
        src="/object-9@2x.png"
      />
      <div className="absolute top-[6450.2px] left-[1244.77px] leading-[150%] capitalize font-semibold [transform:_rotate(8.4deg)] [transform-origin:0_0] z-[5]">
        <p className="m-0"> Test website</p>
        <p className="m-0">functionality,</p>
        <p className="m-0">responsiveness, and</p>
        <p className="m-0">user experience. Make</p>
        <p className="m-0">necessary adjustments.</p>
      </div>
      <img
        className="absolute top-[6436px] left-[1558px] w-[195.1px] h-[195.1px] object-contain z-[7]"
        loading="lazy"
        alt=""
        src="/object-10@2x.png"
      />
      <div className="absolute top-[6391.16px] left-[1454px] leading-[150%] capitalize font-semibold flex items-center [transform:_rotate(-17.3deg)] [transform-origin:0_0] z-[8]">
        <span>
          <p className="m-0"> Deploy the website and</p>
          <p className="m-0">establish maintenance</p>
          <p className="m-0">procedures for updates</p>
          <p className="m-0">and improvements.</p>
        </span>
      </div>
      <FrameComponent5 />
      <section className="absolute top-[6855px] left-[363px] w-[1195px] flex flex-col items-start justify-start gap-[62px] max-w-full text-left text-13xl text-whitesmoke font-jacques-francois">
        <FrameComponent4 />
        <div className="self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px] box-border max-w-full">
          <div className="w-[828px] flex flex-col items-start justify-start gap-[23px] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
              <h2 className="m-0 relative text-inherit leading-[120px] capitalize font-normal font-[inherit] mq450:text-lgi mq450:leading-[72px] mq925:text-7xl mq925:leading-[96px]">
                sales executive Team
              </h2>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-5 mq925:flex-wrap mq925:justify-center">
              <FrameComponent3
                rectangle191="/rectangle-191@2x.png"
                nausheen="01                               Nausheen"
                seniorManager="Senior manager"
              />
              <FrameComponent3
                rectangle191="/rectangle-192@2x.png"
                nausheen="02                        Rohit Sharma"
                propLeft="41px"
                seniorManager="manager"
                propMinWidth="64px"
              />
            </div>
          </div>
        </div>
      </section>
      <GroupComponent />
      <section className="absolute top-[4960px] left-[307px] w-[1306.2px] flex flex-row items-start justify-between max-w-full gap-5 text-center text-117xl text-gray-100 font-inter mq1350:flex-wrap">
        <div className="w-[1089px] flex flex-row items-start justify-start gap-[59px] max-w-full mq925:flex-wrap">
          <div className="h-24 w-[157px] flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border">
            <img
              className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
              loading="lazy"
              alt=""
              src="/attachment-76836612-1@2x.png"
            />
          </div>
          <div className="flex-1 flex flex-row items-start justify-start min-w-[567px] max-w-full mq925:min-w-full">
            <div className="h-[120px] flex-1 relative max-w-full">
              <h1 className="m-0 absolute top-[0px] left-[0px] text-inherit leading-[120px] uppercase font-black font-[inherit] inline-block w-full h-full mq450:text-15xl mq450:leading-[48px] mq925:text-35xl mq925:leading-[72px]">
                TRUSTED BY
              </h1>
              <img
                className="absolute h-[calc(100%_-_28.4px)] top-[14px] bottom-[14.4px] left-[86.5px] max-h-full w-[91.6px] object-contain z-[1]"
                alt=""
                src="/group-237@2x.png"
              />
              <img
                className="absolute top-[31px] left-[323.6px] w-[245.8px] h-[58px] object-contain z-[1]"
                loading="lazy"
                alt=""
                src="/group-239@2x.png"
              />
              <img
                className="absolute h-[calc(100%_-_28px)] top-[14px] bottom-[14px] left-[715px] max-h-full w-[133px] object-contain z-[1]"
                loading="lazy"
                alt=""
                src="/39-1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start pt-3 px-0 pb-0">
          <img
            className="w-24 h-24 relative object-cover"
            alt=""
            src="/group-238@2x.png"
          />
        </div>
      </section>
      <img
        className="absolute top-[157px] left-[1761px] w-[363px] h-[363px] object-cover z-[1]"
        loading="lazy"
        alt=""
        src="/helix2-2-1@2x.png"
      />
      <img
        className="absolute top-[775px] left-[-97px] w-[216px] h-[197px] object-contain z-[3]"
        loading="lazy"
        alt=""
        src="/cylinder-1@2x.png"
      />
      <img
        className="absolute top-[656px] left-[1192px] w-[318.4px] h-[318.4px] object-contain z-[3]"
        alt=""
        src="/halftorus-1@2x.png"
      />
      <img
        className="absolute top-[-18px] left-[1426px] w-[76px] h-[76px] object-contain z-[3]"
        alt=""
        src="/emojistar-1@2x.png"
      />
      <FrameComponent2 />
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default FrameDigital;
