import { FunctionComponent } from "react";

export type FrameComponent7Type = {
  className?: string;
};

const FrameComponent7: FunctionComponent<FrameComponent7Type> = ({
  className = "",
}) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-between max-w-full gap-5 text-center text-81xl text-whitesmoke font-inter mq1825:flex-wrap mq1825:justify-center ${className}`}
    >
      <div className="w-[645px] flex flex-col items-start justify-start pt-[46px] px-0 pb-0 box-border max-w-full text-left text-mediumblue-100">
        <div className="w-[456px] flex flex-col items-start justify-start gap-[26px] max-w-full">
          <h1 className="m-0 self-stretch relative text-inherit leading-[120%] font-semibold font-[inherit] z-[2] mq450:text-11xl mq450:leading-[48px] mq925:text-31xl mq925:leading-[72px]">
            <p className="m-0">Drop us a</p>
            <p className="m-0">line</p>
          </h1>
          <div className="w-[386px] flex flex-col items-start justify-start gap-[46px] max-w-full text-center text-3xl text-whitesmoke mq450:gap-[23px]">
            <div className="relative leading-[120%] capitalize inline-block whitespace-nowrap max-w-full z-[2] mq450:text-lg mq450:leading-[21px]">
              kumarmanas8084@gmail.com
            </div>
            <div className="self-stretch flex flex-row items-start justify-center gap-[30px] mq450:flex-wrap">
              <div className="flex-1 border-dimgray-200 border-[1.5px] border-solid box-border flex flex-row items-start justify-start py-[9px] px-2.5 gap-[2.5px] min-w-[116px] z-[1]">
                <img
                  className="h-[42.4px] w-[43.5px] relative object-contain z-[2]"
                  loading="lazy"
                  alt=""
                  src="/group-221@2x.png"
                />
                <div className="h-16 w-[178px] relative border-dimgray-200 border-[1.5px] border-solid box-border hidden" />
                <div className="flex flex-col items-start justify-start pt-2 px-0 pb-0">
                  <div className="relative leading-[120%] capitalize font-medium inline-block min-w-[107px] mq450:text-lg mq450:leading-[21px]">
                    Whatsapp
                  </div>
                </div>
              </div>
              <div className="flex-[0.871] border-dimgray-200 border-[1.5px] border-solid box-border flex flex-row items-start justify-start py-3 px-5 gap-[9px] min-w-[116px] z-[1] mq450:flex-1">
                <img
                  className="h-9 w-9 relative object-contain z-[2]"
                  alt=""
                  src="/linkedin-2@2x.png"
                />
                <div className="h-16 w-[178px] relative border-dimgray-200 border-[1.5px] border-solid box-border hidden" />
                <div className="flex flex-col items-start justify-start pt-[5px] px-0 pb-0">
                  <div className="relative leading-[120%] capitalize font-medium inline-block min-w-[89px] mq450:text-lg mq450:leading-[21px]">
                    linkedin
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[508px] flex flex-col items-start justify-start gap-[81px] max-w-full text-3xl mq450:gap-5 mq925:gap-10">
        <div className="self-stretch flex flex-row items-start justify-between gap-5 mq450:flex-wrap">
          <div className="relative leading-[27px] capitalize z-[2] mq450:text-lg mq450:leading-[21px]">
            <span>Your Name</span>
            <span className="text-mediumblue-100">*</span>
          </div>
          <div className="relative leading-[27px] capitalize inline-block min-w-[126px] z-[2] mq450:text-lg mq450:leading-[21px]">
            <span>Your email</span>
            <span className="text-mediumblue-100">*</span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[42px] max-w-full mq450:gap-[21px]">
          <div className="relative leading-[120%] capitalize z-[2] mq450:text-lg mq450:leading-[21px]">
            Tell us about your project
          </div>
          <div className="flex flex-col items-start justify-start gap-10 text-xl mq450:gap-5">
            <div className="flex flex-row items-start justify-start py-0 px-6 text-3xl">
              <div className="relative leading-[120%] capitalize inline-block min-w-[111px] z-[2] mq450:text-lg mq450:leading-[21px]">
                Attach file
              </div>
            </div>
            <div className="flex flex-row items-start justify-start pt-0 pb-2 pl-7 pr-0">
              <div className="relative leading-[120%] capitalize z-[2] mq450:text-base mq450:leading-[19px]">
                <span>{`i agree with your `}</span>
                <span className="text-mediumblue-100">privacy policy</span>
              </div>
            </div>
            <div className="w-[180px] rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] flex flex-row items-start justify-end py-[13px] px-[42px] box-border relative z-[2] text-left text-white">
              <div className="h-[50px] w-[180px] relative rounded-31xl [background:linear-gradient(90deg,_#161cf2,_#0d108c_82.62%)] hidden z-[0]" />
              <div className="relative inline-block min-w-[67px] z-[1] mq450:text-base">
                Submit
              </div>
              <img
                className="h-[11.8px] w-[18.6px] absolute !m-[0] bottom-[19px] left-[42px] object-contain z-[1]"
                alt=""
                src="/arrow-8.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start pt-[162px] px-0 pb-0 text-xs">
        <div className="relative leading-[120%] capitalize inline-block min-w-[40px] z-[2]">
          0/1000
        </div>
      </div>
    </div>
  );
};

export default FrameComponent7;
